import { lazy } from "react";
import Loadable from "app/components/Loadable";
import { authRoles } from "../../auth/authRoles";

//

const ListBookingAgent = Loadable(
  lazy(() => import("./BookingAgent/ListBookingAgent"))
);
const AddAndEditBookingAgent = Loadable(
  lazy(() => import("./BookingAgent/AddAndEditBookingAgent"))
);

const ListCarsOwners = Loadable(
  lazy(() => import("./CarsOwners/ListCarsOwners"))
);
const AddAndEditCarsOwners = Loadable(
  lazy(() => import("./CarsOwners/AddAndEditCarsOwners"))
);

const ListFoodsAgent = Loadable(
  lazy(() => import("./FoodsAgent/ListFoodsAgent"))
);
const AddAndEditFoodsAgent = Loadable(
  lazy(() => import("./FoodsAgent/AddAndEditFoodsAgent"))
);

const ListHotelOwners = Loadable(
  lazy(() => import("./HotelOwners/ListHotelOwners"))
);
const AddAndEditHotelOwners = Loadable(
  lazy(() => import("./HotelOwners/AddAndEditHotelOwners"))
);

const ListLaunchOwner = Loadable(
  lazy(() => import("./LaunchOwner/ListLaunchOwner"))
);
const AddAndEditLanchOwner = Loadable(
  lazy(() => import("./LaunchOwner/AddAndEditLanchOwner"))
);

const ListManager = Loadable(lazy(() => import("./Manager/ListManager")));
const AddAndEditManager = Loadable(
  lazy(() => import("./Manager/AddAndEditManager"))
);

const ListAccountant = Loadable(
  lazy(() => import("./Accountant/ListAccountant"))
);
const AddAndEditAccountent = Loadable(
  lazy(() => import("./Accountant/AddAndEditAccountent"))
);

const AddAndEditUsers = Loadable(lazy(() => import("./Users/AddAndEditUsers")));
const ListUsers = Loadable(lazy(() => import("./Users/ListUsers")));
const AddAndEditAhHotel = Loadable(lazy(() => import("./AccessHistory/AddAndEditAhHotel")));

const HotelsList = Loadable(lazy(() => import('./HotelsOwners/HotelList'))) 
const RoomsList = Loadable(lazy(() => import('./HotelsOwners/RoomsList'))) 
const OrdersList = Loadable(lazy(() => import('./HotelsOwners/OrderList'))) 
const CustomerList = Loadable(lazy(() => import('./HotelsOwners/CustomerList'))) 
const HotelAddAndEdit = Loadable(lazy(() => import('./HotelsOwners/HotelAddAndEdit')))  
const RoomAddAndEdit = Loadable(lazy(() => import('./HotelsOwners/RoomAddAndEdit')))  



// const MyStats = Loadable(lazy(() => import('./MyStats/MyStats'))) 

const pagesRoutes = [
  // Super Admin routes
  {
    path: "/pages/booking-agent",
    auth: authRoles.SAdmin,
    element: <ListBookingAgent />,
  },
  {
    path: "/pages/booking-agent-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditBookingAgent />,
  },
  {
    path: "/pages/cars-owner",
    auth: authRoles.SAdmin,
    element: <ListCarsOwners />,
  },
  {
    path: "/pages/cars-owner-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditCarsOwners />,
  },
  {
    path: "/pages/foods-agent",
    auth: authRoles.SAdmin,
    element: <ListFoodsAgent />,
  },
  {
    path: "/pages/foods-agent-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditFoodsAgent />,
  },

  {
    path: "/pages/hotels-owner",
    auth: authRoles.SAdmin,
    element: <ListHotelOwners />,
  },
  {
    path: "/pages/hotels-owner-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditHotelOwners />,
  },

  {
    path: "/pages/launch-owner",
    auth: authRoles.SAdmin,
    element: <ListLaunchOwner />,
  },
  {
    path: "/pages/launch-owner-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditLanchOwner />,
  },

  { path: "/pages/manager", auth: authRoles.SAdmin, element: <ListManager /> },
  {
    path: "/pages/manager-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditManager />,
  },

  {
    path: "/pages/accountant",
    auth: authRoles.SAdmin,
    element: <ListAccountant />,
  },
  {
    path: "/pages/accountant-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditAccountent />,
  },
  {
    path: "/pages/users-list",
    auth: authRoles.SAdmin,
    element: <ListUsers />,
  },
  {
    path: "/pages/users-add-and-edit",
    auth: authRoles.SAdmin,
    element: <AddAndEditUsers />,
  },

  // Booking Agent Routes
  {
    path: "/pages/agents/hotels-owner",
    auth: authRoles.HAgent,
    element: <ListHotelOwners />,
  },

  {
    path: "/pages/agents/cars-owner",
    auth: authRoles.HAgent,
    element: <ListCarsOwners />,
  },

  {
    path: "/pages/agents/launch-owner",
    auth: authRoles.HAgent,
    element: <ListLaunchOwner />,
  },

  {
    path: "/pages/agents/foods-agent",
    auth: authRoles.HAgent,
    element: <ListFoodsAgent />,
  },

  {
    path: "/pages/agents/accountant",
    auth: authRoles.HAgent,
    element: <ListAccountant />,
  },

  { path: "/pages/agents/manager", auth: authRoles.HAgent, element: <ListManager /> },
  { path: "/pages/agents/access-history-add-edit", auth: authRoles.HAgent, element: <AddAndEditAhHotel /> },


  //hotel

  {
    path: "/pages/hotel/cars-owner",
    auth: authRoles.HOwner,
    element: <ListCarsOwners />,
  },

  {
    path: "/pages/hotel/launch-owner",
    auth: authRoles.HOwner,
    element: <ListLaunchOwner />,
  },

  {
    path: "/pages/hotel/foods-agent",
    auth: authRoles.HOwner,
    element: <ListFoodsAgent />,
  },

  {
    path: "/pages/hotel/accountant",
    auth: authRoles.HOwner,
    element: <ListAccountant />,
  },

  { path: "/pages/hotel/manager", auth: authRoles.HOwner, element: <ListManager /> },
  { path: "/pages/hotel/manager", auth: authRoles.HOwner, element: <AddAndEditAhHotel /> },

  { path: "/pages/hotel/hotel-list", auth: authRoles.HOwner, element: <HotelsList /> },
  { path: "/pages/hotel/hotel-add-and-edit", auth: authRoles.HOwner, element: <HotelAddAndEdit /> },
  
  { path: "/pages/hotel/room-list", auth: authRoles.HOwner, element: <RoomsList /> },
  { path: "/pages/hotel/room-add-and-edit", auth: authRoles.HOwner, element: <RoomAddAndEdit /> },

  { path: "/pages/hotel/order-list", auth: authRoles.HOwner, element: <OrdersList /> },
  { path: "/pages/customer-list", auth: authRoles.HOwner, element: <CustomerList /> },


  // Launch Owner 

  {
    path: "/pages/launch/cars-owner",
    auth: authRoles.HAgent,
    element: <ListCarsOwners />,
  },

  {
    path: "/pages/launch/foods-agent",
    auth: authRoles.HAgent,
    element: <ListFoodsAgent />,
  },

  {
    path: "/pages/launch/accountant",
    auth: authRoles.HAgent,
    element: <ListAccountant />,
  },

  { path: "/pages/launch/manager", auth: authRoles.HAgent, element: <ListManager /> },

  // Food Owmer 

  {
    path: "/pages/foods/cars-owner",
    auth: authRoles.HAgent,
    element: <ListCarsOwners />,
  },

  {
    path: "/pages/foods/launch-owner",
    auth: authRoles.HAgent,
    element: <ListLaunchOwner />,
  },

  {
    path: "/pages/foods/accountant",
    auth: authRoles.HAgent,
    element: <ListAccountant />,
  },

  { path: "/pages/foods/manager", auth: authRoles.HAgent, element: <ListManager /> },

];

export default pagesRoutes;
