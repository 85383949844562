import { GET_ALL_HOWNER_BY_AGENT_SUCCESS, GET_ALL_HOWNER_BY_AGENT_ERROR } from "../types/hOwnerStateType"



const initialState = {
    hoError: "",
    hoSMessage: "",
    AHODataByAgent: [] //get all hotel owner datas by agent
  };
  


  
export const HotelStateReducer = (state = initialState, action) => {
    const { payload, type } = action;
    // console.log(payload);
  
    if (type === GET_ALL_HOWNER_BY_AGENT_SUCCESS) {
      return {
        ...state,
        AHODataByAgent: payload.AHODataByAgent
      };
    }
    if (type === GET_ALL_HOWNER_BY_AGENT_ERROR) {
      return {
        ...state,
        hoError: payload.hoError,
      };
    }
    return state;
  }