export const ADMIN_ADD_SUCESS = 'ADMIN_ADD_SUCESS'
export const ADMIN_ADD_FAIL = 'ADMIN_ADD_FAIL'

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'

export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'


export const GET_ALL_BOOKING_AGENTS_SUCCESS = 'GET_ALL_BOOKING_AGENTS_SUCCESS'
export const GET_ALL_BOOKING_AGENTS_ERROR = 'GET_ALL_BOOKING_AGENTS_ERROR'


export const GET_ALL_FOODS_AGENTS_SUCCESS = 'GET_ALL_FOODS_AGENTS_SUCCESS'
export const GET_ALL_FOODS_AGENTS_ERROR = 'GET_ALL_FOODS_AGENTS_ERROR'

export const GET_ALL_HOTELS_OWNERS_SUCCESS = 'GET_ALL_HOTELS_OWNERS_SUCCESS'
export const GET_ALL_HOTELS_OWNERS_ERROR = 'GET_ALL_HOTELS_OWNERS_ERROR'

export const GET_ALL_CARS_OWNERS_SUCCESS = 'GET_ALL_CARS_OWNERS_SUCCESS'
export const GET_ALL_CARS_OWNERS_ERROR = 'GET_ALL_CARS_OWNERS_ERROR'


export const GET_ALL_LAUNCH_OWNERS_SUCCESS = 'GET_ALL_LAUNCH_OWNERS_SUCCESS'
export const GET_ALL_LAUNCH_OWNERS_ERROR = 'GET_ALL_LAUNCH_OWNERS_ERROR'


export const GET_ALL_ACC_SUCCESS = 'GET_ALL_ACC_SUCCESS'
export const GET_ALL_ACC_ERROR = 'GET_ALL_ACC_ERROR'


export const GET_ALL_MANAGERS_SUCCESS = 'GET_ALL_MANAGERS_SUCCESS'
export const GET_ALL_MANAGERS_ERROR = 'GET_ALL_MANAGERS_ERROR'


export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'
