import { MatxSuspense } from 'app/components';
import useSettings from 'app/hooks/useSettings';
import { MatxLayouts } from './index';

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


const MatxLayout = (props) => {
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { error, successMessage, authenticate, token, cAdminData, CurUser, myInfo } = useSelector((state) => state.Admins)

  const Layout = MatxLayouts[settings.activeLayout];


  return (
    <MatxSuspense>
      <Layout {...props} />
    </MatxSuspense>
  );
};

export default MatxLayout;
