import { createContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";


const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};



const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  // login: () => {},
  // logout: () => {},
  // register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, error, successMessage, authenticate, token, myInfo } = useSelector((state) => state.Admins)

  const navigate = useNavigate();
  
  // const login = async (email, password) => {
  //   const response = await axios.post('/api/auth/login', { email, password });
  //   const { user } = response.data;

  //   dispatch({ type: 'LOGIN', payload: { user } });
  // };

  // const register = async (email, username, password) => {
  //   const response = await axios.post('/api/auth/register', { email, username, password });
  //   const { user } = response.data;

  //   dispatch({ type: 'REGISTER', payload: { user } });
  // };

  // const logout = () => {
  //   dispatch({ type: 'LOGOUT' });
  // };



  useEffect(() => {
    if (authenticate === true &&  window.location.pathname !== "/session/signin" && window.location.pathname !== "/session/signup" 
    && window.location.pathname !== "/session/404"  && window.location.pathname !== "/session/reset-password"
    && window.location.pathname !== "*" ) {
      let userRoutes = [] 
      if(children && children.props && children.props.children && children.props.children.length > 1 
        && children.props.children[1].props && children.props.children[1].props.match && 
        children.props.children[1].props.match.route && children.props.children[1].props.match.route.children
        && children.props.children[1].props.match.route.children.length > 0){
          userRoutes = [...children?.props?.children[1]?.props?.match?.route?.children]
        }
      let curUserRoutes = []
      if (userRoutes && userRoutes.length > 0 && myInfo && Object.keys(myInfo).length > 0) {
        for (let i = 0; i < userRoutes.length; i++) {
          let inc = userRoutes[i]?.auth.includes(myInfo?.workRole)
          if (inc) {
            curUserRoutes.push(userRoutes[i])
          }
        }
      }
      if(curUserRoutes && curUserRoutes.length > 0){
        let flag = false
        for(let i = 0; i < curUserRoutes.length; i++){
          if(curUserRoutes[i].path === window.location.pathname){
            flag = true
          }
        }
        if(!flag){
          navigate("/session/404")
        }else{
          children.props.children[1].props.match.route.children = curUserRoutes
        }
      }
    }
  }, [authenticate, myInfo])




  
  useEffect(() => {
    // (async () => {
    //   try {
    // const { data } = await axios.get('/api/auth/profile');
    if (authenticate === true) {
      dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: myInfo } });
    } else {
      dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: {} } });
    }

    //   } catch (err) {
    //     console.error(err);
    //     dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
    //   }
    // })();
  }, [authenticate]);



  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

    return (
      <AuthContext.Provider value={{ ...state, method: 'JWT' }}>
        {children}
      </AuthContext.Provider>
    );

};

export default AuthContext;
