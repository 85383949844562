import useSettings from "app/hooks/useSettings";

const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];
  // const logo = require("../");

  return (
    <img style={{width:'100%'}} src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
  );
};

export default MatxLogo;