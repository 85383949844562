import { configureStore } from "@reduxjs/toolkit";
import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { AdminReducer } from "../store/reducers/adminReducer";
import { HotelStateReducer } from "../store/reducers/hOwnerStateReducer";



const rootReducer = combineReducers({
  Admins: AdminReducer,
  HotelState: HotelStateReducer
});

const store = configureStore({ reducer: rootReducer });


export default store;
