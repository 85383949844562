import {
  ADMIN_ADD_SUCESS,
  ADMIN_ADD_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  SUCCESS_MESSAGE_CLEAR,
  ERROR_CLEAR,
  GET_ALL_BOOKING_AGENTS_SUCCESS,
  GET_ALL_BOOKING_AGENTS_ERROR,
  GET_ALL_FOODS_AGENTS_SUCCESS,
  GET_ALL_FOODS_AGENTS_ERROR,
  GET_ALL_HOTELS_OWNERS_ERROR,
  GET_ALL_HOTELS_OWNERS_SUCCESS,
  GET_ALL_CARS_OWNERS_SUCCESS,
  GET_ALL_CARS_OWNERS_ERROR,
  GET_ALL_LAUNCH_OWNERS_SUCCESS,
  GET_ALL_LAUNCH_OWNERS_ERROR,
  GET_ALL_ACC_SUCCESS,
  GET_ALL_ACC_ERROR,
  GET_ALL_MANAGERS_SUCCESS,
  GET_ALL_MANAGERS_ERROR,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR
} from "../types/adminTypes";
import deCodeToken from 'jwt-decode'

const initialState = {
  loading: true,
  error: "",
  successMessage: "",
  authenticate: false,
  token: '',
  rtoken: "",
  myInfo: {},
  CurUser: {},
  BAgents: [],
  FAgent: [],
  HOwner: [],
  COwner: [],
  LOwner: [],
  Acc: [],
  Manager: [],
  Users: []
};



const tokenDecode = (token) => {
  const tokenDecoded = deCodeToken(token);
  const expTime = new Date(tokenDecoded.exp * 1000);
  if (new Date() > expTime) {
    return null
  }
  return tokenDecoded;
}

const getToken = localStorage.getItem('authToken');
// console.log("42", getToken);
if (getToken && getToken.length > 0) {
  const getInfo = tokenDecode(getToken)
  // console.log("45",getInfo) rtoken
  if (getInfo) {
    initialState.myInfo = getInfo;
    initialState.token = getToken
    initialState.rtoken = getInfo.rtoken ? getInfo.rtoken : ""
    initialState.authenticate = true;
    initialState.loading = false
  }
}



export const AdminReducer = (state = initialState, action) => {
  const { payload, type } = action;
  // console.log(payload);

  if (type === ADMIN_ADD_SUCESS) {
    return {
      ...state,
      successMessage: payload.successMessage,
      data: payload.data
    };
  }
  if (type === ADMIN_ADD_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }
  if (type === USER_LOGIN_SUCCESS) {
    const myInfo = tokenDecode(payload.token);

    return {
      ...state,
      successMessage: payload.successMessage,
      token: payload.token,
      myInfo: myInfo,
      authenticate: true,
      loading: false,
    }
  }
  if (type === USER_LOGIN_ERROR) {
    return {
      ...state,
      error: payload.error
    }
  }
  if(type === GET_ALL_BOOKING_AGENTS_SUCCESS){
    return {
      ...state,
      BAgents: payload.BAgents
    }
  }
  if(type === GET_ALL_BOOKING_AGENTS_ERROR){
    return {
      error: payload.error
    }
  }
  if(type === GET_ALL_FOODS_AGENTS_SUCCESS){
    return {
      ...state,
      FAgent: payload.FAgent
    }
  }
  if(type === GET_ALL_FOODS_AGENTS_ERROR){
    return {
      error: payload.error
    }
  }
  if(type === GET_ALL_HOTELS_OWNERS_SUCCESS){
    return {
      ...state,
      HOwner: payload.HOwner
    }
  }
  if(type === GET_ALL_HOTELS_OWNERS_ERROR){
    return {
      error: payload.error
    }
  }
  if(type === GET_ALL_CARS_OWNERS_SUCCESS){
    return {
      ...state,
      COwner: payload.COwner
    }
  }
  if(type === GET_ALL_CARS_OWNERS_ERROR){
    return {
      error: payload.error
    }
  }
  if(type === GET_ALL_LAUNCH_OWNERS_SUCCESS){
    return {
      ...state,
      LOwner: payload.LOwner
    }
  }
  if(type === GET_ALL_LAUNCH_OWNERS_ERROR){
    return {
      error: payload.error
    }
  }
  if(type === GET_ALL_ACC_SUCCESS){
    return {
      ...state,
      Acc: payload.Acc
    }
  }
  if(type === GET_ALL_ACC_ERROR){
    return {
      error: payload.error
    }
  }
  if(type === GET_ALL_MANAGERS_SUCCESS){
    return {
      ...state,
      Manager: payload.Manager
    }
  }
  if(type === GET_ALL_MANAGERS_ERROR){
    return {
      ...state,
      error: payload.error
    }
  }
  if(type === GET_ALL_USERS_SUCCESS){
    return{
      ...state,
      Users: payload.Users
    }
  }
  if(type === GET_ALL_USERS_ERROR){
    return{
      ...state,
      error: payload.error
    }
  }
  return state;
}