export const authRoles = {
  SAdmin: ["SAdmin"],
  HAgent: ["HAgent"],
  HFAgent: ["HFAgent"],
  HOwner: ["HOwner"],
  COwner: ["COwner"],
  LOwner: ["LOwner"],
  HAcc: ["HAcc"],
  HManager: ["HManager"],
  AROLE: [
    "SAdmin",
    "HAgent",
    "HFAgent",
    "HOwner",
    "COwner",
    "LOwner",
    "HAcc",
    "HManager",
    "User",
  ],

  sa: ["SA"], // Only Super Admin has access
  admin: ["SA", "ADMIN"], // Only SA & Admin has access
  editor: ["SA", "ADMIN", "EDITOR"], // Only SA & Admin & Editor has access
  guest: ["SA", "ADMIN", "EDITOR", "GUEST"], // Everyone has access
};
